import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TheatersIcon from "@material-ui/icons/Theaters";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import ScheduleIcon from "@material-ui/icons/Schedule";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InfoArea from "components/InfoArea/InfoArea";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle";

import bg9 from "assets/img/film-set.jpg";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures1({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div
        className={classes.features5}
        style={{
          backgroundImage: `url(${bg9})`,
        }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                A modular workflow system built exclusively for entertainment
                marketing
              </h2>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.gridContainer}>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={TheatersIcon}
                title="Titles"
                description={
                  <p>
                    Track program titles along with all episodic information,
                    such as production data, licensing terms, talent
                    participation, rights management, storylines, ratings and
                    other details.
                  </p>
                }
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={AccountTreeIcon}
                title="Projects"
                description={
                  <p>
                    Track the entire promo production process in real time. Set
                    and track due dates, create scripts/rough cuts, store EDLs,
                    generate music cue reports, auto-generate logs with
                    time-code for masters, sub-masters and compliation feeds,
                    and manage approvals and assignments.
                  </p>
                }
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={VideoLibraryIcon}
                title="Library"
                description={
                  <p>
                    Automatically track each media element as it moves through
                    the production process with ON-AIR Pro's built-in barcode
                    labeling and reading capabilities. Logs for promo tapes are
                    automatically built from the PROJECTS module.
                  </p>
                }
                iconColor="primary"
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.gridContainer}>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={ScheduleIcon}
                title="Sessions"
                description={
                  <p>
                    Build daily and weekly session schedules and assignment
                    sheets, either manually or from an unlimited number of
                    user-defined templates. Promos or graphics orders can be
                    selected from the PROJECTS module and assigned to the
                    workload for a given session.
                  </p>
                }
              />
            </GridItem>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={GraphicEqIcon}
                title="Graphics"
                description={
                  <p>
                    Manage your on-air department's graphics. Track work orders,
                    completion and approval dates, and artist information with
                    links to the PROJECTS module. Store graphic elements and key
                    frames within the ON-AIR Pro system for easy access and
                    viewing.
                  </p>
                }
              />
            </GridItem>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={PermContactCalendarIcon}
                title="Contacts"
                description={
                  <p>
                    Store all users of the system, vendors, shipping locations,
                    clients and any other contact associated with on-air. Manage
                    users, print labels, letters, and store addresses and phone
                    numbers.
                  </p>
                }
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
