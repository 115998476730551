import * as React from "react";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle";
import "typeface-roboto";
import "typeface-roboto-slab";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import MyHeader from "components/Header/MyHeader";
import MyFooter from "components/Footer/MyFooter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Button from "components/CustomButtons/Button.js";

// Sections for this page
import SectionFeatures1 from "./OnAirProPage/Sections/SectionFeatures1";
import SectionFeatures2 from "./OnAirProPage/Sections/SectionFeatures2";
import SectionProjects from "./OnAirProPage/Sections/SectionProjects";

const useStyles = makeStyles(landingPageStyle);

// markup
const OnAirProPage = ({ ...rest }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  return (
    <div>
      <MyHeader {...rest} />
      <Parallax image={require("assets/img/scripts_music.jpg")} filter="dark">
        <div className={classes.container}>
          <GridContainer alignItems="flex-end" justifyContent="space-between">
            <GridItem xs={9}>
              <GridContainer alignItems="center" justifyContent="space-between">
                <GridItem xs={6} sm={3}>
                  <img
                    src={require("assets/img/logos/oap-logo.png")}
                    width="100%"
                    alt="ON-AIR Pro"
                  />
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <h2 className={classes.title}>
                    Your Creative Workflow Management System
                  </h2>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={9}>
              <h4>
                ON-AIR Pro is a modular workflow system built exclusively for
                entertainment marketing to flexibly manage the complete on-air
                promotion process. Now in the Cloud!
              </h4>
            </GridItem>
            <GridItem xs={9} style={{ textAlign: "end" }}>
              <Button color="danger" size="lg" href="/" target="_blank">
                <i className="fas fa-play" />
                Book Your Demo
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <SectionFeatures1 />
        </div>
        <SectionFeatures2 />
        <SectionProjects />
      </div>
      <MyFooter {...rest} />
    </div>
  );
};

export default OnAirProPage;
